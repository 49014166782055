var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "lg": "9",
      "md": "10",
      "sm": "12"
    }
  }, [_c('b-card', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('a', {
    staticClass: "btn btn-info",
    attrs: {
      "href": _vm.qrCode,
      "download": "QR Code ".concat(this.asset.nama)
    }
  }, [_vm._v("Download")])]), _vm.asset.qrcode ? _c('div', {
    staticClass: "w-100 d-flex flex-column",
    attrs: {
      "id": "qrcode-container"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('img', {
    staticClass: "d-block mb-1",
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "src": _vm.asset.qrcode,
      "alt": "QR Code"
    }
  })])]) : _vm._e(), _vm.asset.qrcode && _vm.qrCode ? _c('div', {
    staticClass: "d-flex justify-content-center mb-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.printQrCode($event);
      }
    }
  }, [_vm._v("Cetak")])], 1) : _vm._e(), _c('table', {
    staticClass: "table"
  }, [_c('tr', [_c('td', [_vm._v("Nama Aset")]), _c('th', [_vm._v(_vm._s(_vm.asset ? _vm.asset.nama : ''))])]), _c('tr', [_c('td', [_vm._v("Kode")]), _c('th', [_vm._v(_vm._s(_vm.asset ? _vm.asset.kode : ''))])]), _c('tr', [_c('td', [_vm._v("Jenis Aset")]), _c('th', [_vm.asset ? _c('div', [_vm._v(" " + _vm._s(_vm.asset.jenis == 1 ? 'TETAP' : 'BHP') + " ")]) : _vm._e()])]), _c('tr', [_c('td', [_vm._v("Kategori")]), _c('th', [_vm.asset ? _c('div', [_vm._v(" " + _vm._s(_vm.asset.kategori ? _vm.asset.kategori.kategori : '') + " ")]) : _vm._e()])]), _c('tr', [_c('td', [_vm._v("Volume")]), _c('th', [_vm._v(_vm._s(_vm.asset ? _vm.asset.volume : ''))])]), _c('tr', [_c('td', [_vm._v("Harga Dasar ")]), _c('th', [_vm._v("Rp " + _vm._s(_vm.asset ? _vm.formatRupiah(_vm.asset.harga_dasar) : 0))])]), _c('tr', [_c('td', [_vm._v("Keterangan")]), _c('th', [_vm._v(_vm._s(_vm.asset ? _vm.asset.keterangan : ''))])]), _c('tr', [_c('td', [_vm._v("Stok")]), _c('th', [_vm._v(_vm._s(_vm.asset && _vm.asset.stok ? _vm.asset.stok.penyimpanan + _vm.asset.stok.peminjaman : ''))])]), _c('tr', [_c('td', [_vm._v("Satuan")]), _c('th', [_vm.asset ? _c('div', [_vm._v(" " + _vm._s(_vm.asset.satuan ? _vm.asset.satuan.satuan : '') + " ")]) : _vm._e()])])])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }